import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "../assets/css/Home.css";
import Loader from "../Components/Loader";
import axios from "axios";
import { Config } from "../Config";
import "aos/dist/aos.css";
import Aos from "aos";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const Blogs = () => {

    const [loading, setLoading] = useState(true);

    const [blogData, setBlogData] = useState([]);

    useEffect(() => {
        axios.get(`${Config.apiUrl}/blogs`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                const responseData = response.data.contact_page.blog;
                //console.log("responseData",responseData);
                const mappedData = responseData.map(blog => ({
                    id: blog.id,
                    date: formatDate(blog.created_at),
                    title: blog.title?.slice(0, 40),
                    content: blog.content?.replace(/<[^>]+>/g, '').length > 100 ?
                        blog.content?.replace(/<[^>]+>/g, '').substring(0, 100) + '...' : blog.content,
                    imgSrc: blog.main_image,
                    slug: blog.slug,
                    tags: blog.tags ? blog.tags.split(',').map(tag => `#${tag.trim()}\u00A0\u00A0`).join(' ') : [],
                }));

                setBlogData(mappedData);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was a problem fetching the city data:', error);
                setLoading(false);
            });

    }, []);

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    return (
        <>
            <Helmet>
                <title>Affordable Jan Awas Yojna Plots in Haryana, Farrukhnagar, Dwarka Expressway, Rohtak</title>
                <meta name="description" content="Explore affordable Deen Dayal plots under Jan Awas Yojna in Haryana. Search for 'Deen Dayal Plots Near Me' to find the best options." />
                <meta name="keywords" content="deen dayal jan awas yojna, deen dayal awas yojna plots, deen dayal jan awas yojna plots,deen dayal affordable plots,
        deen dayal plots near me,deen dayal plots,deen dayal awas yojna haryana,Deen Dayal jan Awas yojna plot in Farrukhnagar,Deen Dayal jan Awas yojna plot in Dwarka Expressway,
        Deen Dayal plot in Rohtak,Deen Dayal jan Awas yojna plot in Rohtak,jan awas plots near me,DDJAY PLOTS" />
            </Helmet>
            {
                loading && <Loader />
            }
            <Navbar />

            <section className="py-5">
                <div className="container-box py-md-3">
                    <div className="">
                        <div className="">
                            <h2 className="fw-bold text-green text-center text-capitalize">
                                Blogs
                            </h2>
                            <div className="line mt-4 mb-5 mx-auto"></div>
                        </div>

                        <div className="mt-5">
                            <div className="row">
                                {blogData?.map((blog) => (
                                    <div className="col-md-4 mb-5" key={blog.id}>
                                        <div className="blog-content1">
                                            <div>
                                                <img src={`${Config.imageUrl}/blogs/${blog.imgSrc}`} alt={`Blog ${blog.id}`}></img>
                                            </div>
                                            <div className="blog-section">
                                                <h5>{blog.date}</h5>
                                                <h4>{blog.title}.. </h4>
                                                <div dangerouslySetInnerHTML={{ __html: blog.content }}></div>
                                                {/* <div>{blog.tags}</div> */}
                                                <span>
                                                    <NavLink to={`${Config.appUrl}blogdetail/${blog.slug}`}>Read More <i className="bi bi-chevron-double-right"></i></NavLink>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )

}
export default Blogs