import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import axios from 'axios';
import { Config } from '../Config';
import moment from 'moment';
import Loader from './Loader';

const NewsUpdate = () => {

    const [newsData, setNewsData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.get(`${Config.apiUrl}/project_info`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                setNewsData(response.data?.project_page?.news)
                window.scrollTo({ top: 0, behavior: "instant" })
            })
            .catch(error => {
                console.error('There was a problem fetching the data:', error);
            }).finally(() => {
                setLoading(false)
            })
    }, []);

    return (
        <>
            {loading && <Loader />}
            <Navbar />

            <section className="py-4 pt-2">
                <div className="container-box py-md-4">
                    <div className=''>
                        <div className=" ">
                            <h2 className="fw-bold text-capitalize  text-green">
                                News Updates
                            </h2>
                            <div className="line mt-4 mb-5"></div>
                        </div>

                        <div className="mt-3">
                            <div className="row">
                                {
                                    newsData?.length > 0 ? (
                                        newsData.map((news) => (
                                            <a key={news.id} href={`${Config.docUrl}${news.pdf}`}
                                                onClick={(e) => {
                                                    if (!news.pdf)
                                                        e.preventDefault()
                                                }}
                                                target='_blank'
                                                className='d-block text-dark text-decoration-none'>
                                                <div className="d-flex flex-column">
                                                    <div className="title-marquee">
                                                        <i className="bi bi-calendar-date me-3 text-blue"></i>
                                                        <h4 className="heading-marquee">{moment(news.date).format("DD MMM YYYY")}
                                                            <span className='text-animated d-inline rounded ms-1 badge lh-sm' style={{ fontSize: "12px" }}> <i className='bi bi-arrow-left fs-6 me-1'></i> Check Now  </span>
                                                        </h4>
                                                    </div>

                                                    <p className="paragraph-marquee">
                                                        {news.news}
                                                    </p>
                                                    <div className="line-marquee"></div>
                                                </div>
                                            </a>
                                        ))
                                    ) : (
                                        <p>No news available</p>
                                    )
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default NewsUpdate