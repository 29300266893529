import React, { useEffect, useState, useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router";
import { Config } from "../Config";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Loader from "./Loader";
import { Helmet } from "react-helmet";
import CommentForm from "./Elements/CommentForm";
import User1 from "../assets/Images/user-info.png"
import moment from "moment";
const BlogDetail = () => {

    const [blogData, setBlogData] = useState([]);
    const [blog, setBlog] = useState({});
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);

    const [fixedSearch, setFixedSearch] = useState(false);

    const { slug } = useParams();

    const lastRef = useRef(null)

    useEffect(() => {
        document.addEventListener("scroll", () => {
            setFixedSearch((window.scrollY > 150 && window.scrollY < (lastRef?.current?.offsetTop-600)) ? true : false)
        })
    }, [])

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        axios.get(`${Config.apiUrl}/blogs/${slug}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                const responseData = response.data.blog_page.latestBlog
                console.log(response)
                setBlog(response.data?.blog_page?.blog)
                setComments(response.data?.blog_page?.comments)

                console.log("responseData", responseData);
                const mappedData = responseData.map(blog => ({
                    id: blog.id,
                    date: formatDate(blog.created_at),
                    title: blog.title,
                    content: blog.content,
                    imgSrc: blog.main_image,
                    slug: blog.slug,
                    tags: blog.tags
                }));
                setBlogData(mappedData);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was a problem fetching the city data:', error);
            });

    }, [slug]);

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    // const blog = blogData.find((blog) => blog.slug === slug);

    const [searchQuery, setSearchQuery] = useState(""); // State to manage search query
    const filteredBlogs = blogData.filter((blog) =>
        blog.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <Helmet>
                <title>{blog && blog?.title}</title>
            </Helmet>
            {/* {slug && slug === "overview-of-deen-dayal-jan-awas-yojna" ? (
                <Helmet>
                    <title>Affordable Housing with Deen Dayal Jan Awas Yojna</title>
                    <meta name="description" content="Discover the benefits of Pradhan Mantri Jan Awas Yojna with DDJAY. Find affordable plots under the Jan Awas Yojna and PM Jan Awas Yojana scheme." />
                    <meta name="keywords" content="pradhan mantri jan awas yojna,jan awas yojna,pm jan awas yojana,jan awas scheme,jan awas plots,jan awas plots near me" />
                </Helmet>
            ) : slug && slug === "how-to-choose-the-right-plot-under-deen-dayal-jan-awas-yojna" ? (
                <Helmet>
                    <title>How to Choose the Right HUDA Plot in New Gurugram</title>
                    <meta name="description" content="Discover perfect HUDA plot in Gurugram under the Deen Dayal Jan Awas Yojna. Explore govt housing schemes, freehold plots, and sasta plots in Haryana." />
                    <meta name="keywords" content="HUDA plot in new gurgoan,HUDA plot in Gurugram,govt housing schemes,Huda Plot,Freehold plot,Sasta plot in haryana" />
                </Helmet>
            ) : (
                <></>
            )} */}
            {loading && <Loader />}
            <Navbar />

            <section className="py-5" style={{ backgroundColor: '#f4faf0' }}>
                <div>
                    <div className="">
                        <h2 className="fw-bold text-green text-center text-capitalize">
                            Blog Details
                        </h2>
                        <div className="line mt-3 mb-3 mx-auto"></div>
                    </div>

                    {blog &&
                        <div className="container-box py-md-4">
                            <div className="row">
                                <div className="col-md-8 p-0 col-box bg-white">
                                    <div className="bg-white p-5">
                                        <img src={`${Config.imageUrl}/blogs/${blog?.main_image}`} className="blog-img"></img>
                                        <div>
                                            <div className="d-inline-block border px-3 py-2 mt-3 border-dark text-green">
                                                Views : {blog?.views}
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            <div>
                                                <span>
                                                    <h2>
                                                        {blog?.title}
                                                    </h2>
                                                    <p>
                                                        <div dangerouslySetInnerHTML={{
                                                            __html:
                                                                blog?.content?.replace(/style="[^"]*"/gi, '')
                                                        }}></div>
                                                    </p>
                                                    <div>
                                                        {blog?.tags && blog?.tags.split(',').map(tag => `#${tag.trim()}\u00A0`).join('  ')}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>



                                        {/* ================================================ */}
                                        <div className="mt-5">
                                            <h3 className="main-title fs-3 mb-4">All Comments</h3>
                                            <div className="comments_box mt-4 ">
                                                {comments?.length == 0 && (
                                                    <p className="text-danger">
                                                        No Comment Available, Be the First Commenter.
                                                    </p>
                                                )}

                                                {comments?.map((comment) => {
                                                    return (
                                                        <div className="d-flex align-items-center gap-3 mb-4">
                                                            <div>
                                                                <img src={User1} alt="No Img" className="comment_user" />
                                                            </div>
                                                            <div className="comment-text" style={{ flex: 1 }}>
                                                                <div className="d-flex gap-3 w-100 align-items-center justify-content-between">
                                                                    <h5 className="mb-0">{comment?.name}</h5>
                                                                    <span className="bg-">
                                                                        <i className="bi bi-calendar me-2"></i>
                                                                        {moment(comment?.created_at).format(
                                                                            "DD MMMM YYYY"
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <p className="mb-0 mt-1">{comment?.feedback}</p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>

                                        <div className="mt-5">
                                            <CommentForm blog={blog} setComments={setComments} />
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-md-4 ${fixedSearch && "fixed_search"}`}>
                                    <div className="bg-white p-4 recent_blog">
                                        <div className="position-relative">
                                            <i className="bi bi-search search-icon"></i>
                                            <input type="search" className="py-2 px-5 searchbar"
                                                placeholder="Search Blogs.."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            >
                                            </input>
                                        </div>
                                    </div>
                                    <div className="bg-white p-4 mt-4">
                                        <h5>Recent Blogs</h5>
                                        <div className="mt-4">
                                            {filteredBlogs.map((recentBlog) => (
                                                <div className="recent-blog mb-3" key={recentBlog.id}>
                                                    <div>
                                                        <NavLink to={`${Config.appUrl}blogdetail/${recentBlog.slug}`}>
                                                            <img src={`${Config.imageUrl}/blogs/${recentBlog.imgSrc}`} alt={`Blog ${recentBlog.id}`} />
                                                        </NavLink>
                                                    </div>
                                                    <div className="ms-3">
                                                        <NavLink to={`${Config.appUrl}blogdetail/${recentBlog.slug}`} className="nav-link"> {/* Use Link instead of anchor tag */}
                                                            <h6 className="mb-1">{recentBlog.title}</h6>
                                                        </NavLink>
                                                        <p>{recentBlog.date}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div ref={lastRef} style={{ height: "2px" }}></div>
                        </div>
                    }
                </div>
            </section>

            <Footer />
        </div>
    )

}
export default BlogDetail