import React, { useState, useEffect } from "react";
import logo from "../assets/Images/deen-dayal.png";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { Config } from "../Config";
import { configure } from "@testing-library/react";

const Navbar = () => {
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [policy, setPolicyPage] = useState([]);
  const [contactSection, setContactSection] = useState({});

  window.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    axios.get(`${Config.apiUrl}/policy`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {

        const data = response.data.policy_page;
        setPolicyPage(data.policy);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${Config.apiUrl}/contactpage`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        const data = response.data.contact_page;
        setContactSection(data.contact_section);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);

      });
  }, []);

  // Get the height of the entire document
  const fullPageHeight = Math.max(
    document.body.scrollHeight, document.body.offsetHeight,
    document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight
  );

  const openAndDownloadFile = async (url, filename, event) => {
    event.preventDefault();

    try {
      window.open(url, '_blank');

      const response = await fetch(url, { crossdomain: true });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status} - ${response.statusText}`);
      }

      const blob = await response.blob();
      const url1 = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url1;
      link.download = `${filename}.pdf`;
      link.click();
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <div className="border-bottom">
      <nav
        className={`pd-navbar shadow ${stickyNavbar ? fullPageHeight >= 900 ? "skicky-navbar" : "absolute-navbar" : ""
          }`}
      >

        <div className="container-box">
          <div className="row justify-content-between align-items-center py-md-2">
            <div className="col-md-3 col-6 text-md-start ">
              <Link to={`${Config.appUrl}`} className="navbar-brand">
                <img
                  src={logo}
                  alt="Logo"
                  className="logo "
                  onClick={() => window.scrollTo(0, 0)}
                ></img>
              </Link>
            </div>

            <div className="col-md-8">
              <div className="py-md-2 top-contact">
                <div className="d-md-flex justify-content-end">
                  {/* <div className="mt-1 px-2 py-2 mx-2  rounded listbox-content">
                    <h4 className='mb-0 text-green text-decoration-none text-bold d-block text-center' style={{ fontWeight: 'bold' }}>Loan Available</h4>
                  </div> */}
                  <div className="me-md-4 width-100">
                    <i className="bi bi-envelope-fill text-green me-2"></i>
                    <a
                      href={`mailto:${contactSection.contact_mails}`}
                      className="text-green text-decoration-none"
                    >
                      {contactSection.contact_mails}
                    </a>
                  </div>

                  <div className="width-100">
                    <i className="bi bi-telephone-fill text-green me-2"></i>
                    <a
                      href={`tel:${contactSection.contact_numbers}`}
                      className="text-green text-decoration-none"
                    >
                      {contactSection.contact_numbers}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <i
              className={`bi bi-${mobileMenu ? "x" : "list"} fs-2 text-end menu-btn`}
              onClick={() => setMobileMenu(!mobileMenu)}
            ></i>

          </div>
        </div>
        <div className="col-12 bg-green" >
          <ul className={`navlinks ${mobileMenu && "show-menu"}`}>
            {/*  */}
            <li>
              <NavLink to={`${Config.appUrl}`}>HOME</NavLink>
            </li>

            <li>
              <NavLink to={`${Config.appUrl}about-us`}>About Us</NavLink>
            </li>

            <li>
              <NavLink to={`${Config.appUrl}all-projects`}>Projects</NavLink>
            </li>

            <li>
              <NavLink to={`${Config.appUrl}blogs`}>Blogs</NavLink>
            </li>

            <li>
              <ul className="dropdown w-100 ">
                <li className="dropdown-item " id="dropdown-toggle">

                  <NavLink
                    className=" position-relative border-0"
                    // to={`${Config.appUrl}affordable/policy`}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    Policy
                    <i
                      className="dropdown-toggle item-icon ms-2"

                    ></i>
                  </NavLink>

                  <ul className={`submenu ${isDropdownOpen && "d-block"}`} id="submenu">
                    {policy.map((policy, index) => (
                      <li key={index}>
                        <a
                          href={`${Config.docUrl}${policy.description}`}
                          onClick={(event) => openAndDownloadFile(`${Config.docUrl}${policy.description}`, policy.title, event)}
                          className="text-capitalize"
                        >
                          {policy.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </li>



            {/* <li className="d-flex align-items-center">

                  <ul className="dropdown w-100 ">
                    <li className="dropdown-item " id="dropdown-toggle">

                      <NavLink
                        className=" position-relative border-0"
                        to={`${Config.appUrl}affordable/policy`}
                        state={{
                          sector: "I",
                        }}
                      >
                        Policy
                        <i
                          className="dropdown-toggle item-icon ms-2"
                          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        ></i>
                      </NavLink>

                      <ul
                        className={`submenu ${isDropdownOpen && "d-block"}`}
                        id="submenu"
                      >
                        <li>
                          <a href={Policy1} download className="text-capitalize">
                            AGH policy instructions
                          </a>
                        </li>
                        <li>
                          <a href={Policy2} download className="text-capitalize">
                            Letter AGH 2013 amendment
                          </a>
                        </li>
                        <li>
                          <a href={Policy3} download className="text-capitalize">
                            Notification Affordable Hou..
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li> */}
            <li>
              <NavLink to={`${Config.appUrl}get-in-touch`}>Contact Us</NavLink>
            </li>
          </ul>

        </div>
      </nav>
    </div>
  );
};

export default Navbar;
