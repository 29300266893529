import axios from 'axios';
import React, { useState } from 'react'
import { Config } from '../../Config';
import { notification } from 'antd';

const CommentForm = ({ blog, setComments }) => {

    const [comment_name, setCommentName] = useState("");
    const [comment_email, setCommentEmail] = useState("");
    const [comment_message, setCommentMessage] = useState("");
    const [errors2, setErrors2] = useState({});
    const [loading, setLoading] = useState(false);

    const validation2 = () => {
        const newErrors = {};

        if (!comment_name) {
            newErrors.comment_name = "Name is required";
        }

        if (!comment_email) {
            newErrors.comment_email = "Email is required";
        } else if (
            !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(comment_email)
        ) {
            newErrors.comment_email = "Invalid email format";
        }

        if (!comment_message) {
            newErrors.comment_message = "Message is required";
        }

        setErrors2(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const onAddComment = async (e) => {
        e.preventDefault();
        if (validation2()) {
            setLoading(true);
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append("blog_id", blog.id);
                formData.append("name", comment_name);
                formData.append("email", comment_email);
                formData.append("feedback", comment_message);
                formData.append("blog_name", blog?.title);

                const res = await axios.post(`${Config.apiUrl}/comments`, formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                });
                if (res.status == 200) {
                    notification.open({
                        message: (
                            <div className="d-flex align-items-center">
                                <div className="pr-20">
                                    <i
                                        className="bi bi-check2-circle text-success me-2"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                </div>
                                <div className="succes-message">
                                    Your Comment has been Added
                                </div>
                            </div>
                        ),
                        description: "Comments Added Successfully",
                    });
                    setComments((prevItems) => [...prevItems, res?.data?.data]);
                    setCommentEmail("");
                    setCommentMessage("");
                    setCommentName("");
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    };
    return (
        <div>
            <form onSubmit={onAddComment}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <input
                            type="text"
                            placeholder="Enter Full Name*"
                            className=" form-field border border-2"
                            value={comment_name}
                            onChange={(e) => setCommentName(e.target.value)}
                        />
                        {errors2.comment_name && (
                            <span className=" text-danger span-error">
                                {errors2.comment_name}
                            </span>
                        )}
                    </div>
                    <div className="col-md-6 mb-3">
                        <input
                            type="text"
                            placeholder="Enter Email Address*"
                            className=" form-field border border-2"
                            value={comment_email}
                            onChange={(e) => setCommentEmail(e.target.value)}
                        />
                        {errors2.comment_email && (
                            <span className=" text-danger span-error">
                                {errors2.comment_email}
                            </span>
                        )}
                    </div>
                    <div className="col-md-12 mb-3">
                        <textarea
                            type="text"
                            placeholder="Message Here*"
                            className=" form-field border border-2"
                            value={comment_message}
                            rows={4}
                            onChange={(e) =>
                                setCommentMessage(e.target.value)
                            }
                        />
                        {errors2.comment_message && (
                            <span className=" text-danger span-error">
                                {errors2.comment_message}
                            </span>
                        )}
                    </div>
                    <div className="mt-2">
                        <button className="button text-decoration-none" disabled={loading}>
                            <i className={`bi bi-arrow-repeat ${loading && 'd-inline-block'} loader me-2`}></i> Add Comment
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CommentForm