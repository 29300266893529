import React from 'react'
import { Link } from 'react-router-dom'
import { Config } from '../Config';
import swal from 'sweetalert';

const CTASection = ({ data }) => {

    const cta_section = data?.ctaSection;
    const products = cta_section?.projects?.filter(p => p.status === 1)

    return (
        <section className='py-5 bg-cta'
            style={{
                backgroundImage: `linear-gradient(rgba(34, 34, 34, 0.7), rgba(34, 34, 34, 0.7)),url(${Config.imageUrl}/${cta_section.cta_bg})`,
                backgroundPosition: "center"
            }}>
            <div className="container-box py-md-3">

                <div className={`text-center ${products?.length <= 2 && 'd-lg-flex'} cta-btns  align-items-center justify-content-between`}>
                    {
                        products?.length === 0 ?
                            <>
                                <h2 className='text-white display-6 mb-0' style={{ fontWeight: "600" }}> {cta_section.cta_section_text} </h2>
                                <button onClick={() => swal("", "Sorry! Registration is closed!", "error")} className="button text-decoration-none bg-red">
                                    Registration Closed
                                </button>

                            </> :
                            <>
                                <h2 className='text-white display-6 mb-0' style={{ fontWeight: "600" }}> {cta_section.cta_section_text}  </h2>

                                <div className='d-md-flex flex-wrap align-items-center justify-content-center'>
                                    {
                                        products?.map((item, itemIndex) => (
                                            <div key={itemIndex} className={`${products?.length > 2 ? 'mt-5' : 'mt-md-0 mt-5'}  mx-lg-5 mx-4`}>
                                                <h2 className='mb-4 text-white '>{item.project_name}</h2>
                                                <Link to={`${Config.appUrl}all-projects`}>
                                                    <button className="button text-decoration-none">
                                                        Registration Open
                                                    </button>
                                                </Link>
                                            </div>
                                        ))
                                    }

                                </div>

                            </>
                    }

                </div>
            </div>
        </section>
    )
}

export default CTASection