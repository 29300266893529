import React, { useEffect, useState } from 'react'
import ListBox from './Elements/ListBox'

// import topBanner from "../assets/Images/banner-image.png";
import ImageSlider from './ImageSlider';
import { Config } from '../Config';
import moment from 'moment';
import Marquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';

const MainHomeSection = ({ data }) => {

  const [swarnim1_download, setswarnim1_download] = useState([])
  const [swarnim2_download, setswarnim2_download] = useState([])
  const [projectInfo, setProjectInfo] = useState([])
  const [policyData, setPolicyData] = useState([])
  const [newsData, setNewsData] = useState([])
  const [loanStatus, setLoanStatus] = useState([])
  const [bankName, setBankName] = useState([]);
  const [reg_text, seRegText] = useState([]);

  useEffect(() => {

    setProjectInfo(
      {
        heading: {
          text: "Ongoing Projects",
          bgcolor: "bg-green"
        },
        list: data?.mainHeaderData?.project_info
          .filter(pr => pr.status === 1)
          .map(item => ({
            title_sm: "",
            title_lg: item.project_name,
            name: item.project_name,
            url: `${Config.appUrl}projectdetails/${item.project_name.replaceAll(" ", "-").toLowerCase()}`

          }))
      }
    )


    setPolicyData(
      {
        heading: {
          text: "Closed Projects ",
          bgcolor: "bg-green"
        },
        list: data?.mainHeaderData?.project_info
          .filter(pr => pr.status === 0)
          .slice(0, 2).map(item => ({
            title_sm: "",
            title_lg: `${item.project_name}`,
            name: item.project_name,
            url: `${Config.appUrl}projectdetails/${item.project_name.replaceAll(" ", "-").toLowerCase()}`
          }))?.slice(0, 3)
      }
    )

    setNewsData(data?.mainHeaderData?.news)

    const down_data = data?.downloadData?.map((item) => ({ ...item, list: item.list.slice(0, 4) }))

    if (down_data?.length > 0) {
      setswarnim1_download(down_data[0])
      setswarnim2_download(down_data[1])
    }

    setLoanStatus(data?.banner_section?.loan_btn);
    setBankName(data?.banner_section?.bank_name);
    seRegText(data?.banner_section?.registration_price);
    // console.log("bank", data?.banner_section?.bank_name);
  }, [data]);

  const [logo, setLogo] = useState(null);

  // useEffect(() => {
  //   // Function to map bank names to corresponding logos
  //   const getBankLogo = (bankName) => {
  //     switch (bankName && bankName.toLowerCase()) {
  //       case 'sbi':
  //         return sbi;
  //       case 'icici':
  //         return icici;
  //       case 'hdfc':
  //         return hdfc;
  //       case 'bankofbaroda':
  //         return bob;
  //       case 'bankofindia':
  //         return bankOI;
  //       case 'axis':
  //         return axis;
  //       case 'indusind':
  //         return IndusInd;
  //       case 'punjabnationalbank':
  //         return pnb;
  //       default:
  //         return null;
  //     }
  //   };

  //   // Set the logo based on the bank name
  //   setLogo(getBankLogo(data?.banner_section?.bank_name));
  // }, [data?.banner_section?.bank_name]);

  // const responseString = bankName;
  // console.log("responseString", responseString);

  // const bankNamesArray = responseString.slice(1, -1).split(',');

  // const trimmedBankNames = bankNamesArray.map(name => name.trim());

  const [expiryTime, setExpiryTime] = useState('');
  const [expiry, setExpiry] = useState(true);

  useEffect(() => {
    const formattedDatetime = new Date(data?.banner_section?.register_datetime).toLocaleString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    });
    setExpiryTime(formattedDatetime);
  },);

  useEffect(() => {
    if (expiryTime) {
      countdownTimer();
    }
  }); // Add expiryTime as a dependency

  const [countdownTime, setCountdownTime] = useState({
    countdownDays: 0,
    countdownHours: 0,
    countdownMinutes: 0,
    countdownSeconds: 0
  });

  const countdownTimer = () => {
    const timeInterval = setInterval(() => {
      const countdownDateTime = new Date(expiryTime).getTime();
      const currentTime = new Date().getTime();
      const remainingTime = countdownDateTime - currentTime;

      if (remainingTime < 0) {
        clearInterval(timeInterval);
        setExpiry(false);
      } else {
        const totalDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const totalHours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const totalMinutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const totalSeconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        setCountdownTime({
          countdownDays: totalDays,
          countdownHours: totalHours,
          countdownMinutes: totalMinutes,
          countdownSeconds: totalSeconds
        });
      }
    }, 1000);
  };

  //console.log("NewsData", newsData);

  return (
    <section className=" pt-4 mt-2">
      <div className="container-box">
        <div className="row">
          <div className="col-lg-3">
            <ListBox data={projectInfo} highlightText={true} />
            <ListBox data={policyData} />
            <div className='mb-4'>
              <h6 className={`px-4 py-3 rounded text-white text-center listbox-heading bg-green`}>
                Bank Loan
              </h6>

              <div className="mt-1 px-3 py-3 bg-light-green rounded listbox-content">
                {typeof bankName === 'string' && bankName.split(',').map((bank, index) => (
                  <div key={index} className="py-2 d-flex align-items-center bank_sec">
                    <i className="bi bi-check-all fs-5 me-2"></i>
                    <div>
                      <span style={{ fontSize: '17px' }}>{bank}</span>
                    </div>
                  </div>
                ))}
              </div>

            </div>
          </div>
          <div className="col-lg-6">
            <div className="text-center border-bottom bg-white mb-4">
              <img src={`${Config.imageUrl}/${data.banner_img}`} alt="banner" className="img-fluid w-100 border p-1" />
            </div>
            <div>
              <ImageSlider data={
                {
                  banner_img: data?.banner_img,
                  images: data?.images,
                }
              } />
            </div>
          </div>

          <div className="col-lg-3 mt-lg-0 mt-4">
            <div className="mt-1 mb-4 px-3 py-3 bg-light-green rounded listbox-content">
              <h6 className='mb-0 text-decoration-none text-bold d-block text-center'>{reg_text}</h6>
            </div>


            <div className='mb-3'>
              {expiry !== false ?
                <h6 className={`px-4 py-3 rounded text-white text-center listbox-heading bg-green`}>

                  <div>Registration Open</div></h6> :
                <h6 className={`px-4 py-3 rounded text-white text-center listbox-heading bg-red`}>

                  <div>Registration Closed</div></h6>

              }

              <div className="mt-1 py-1 ">
                <div className="btn-group d-flex flex-wrap" style={{ width: "100%" }}>
                  {expiry !== false ?
                    <>
                      <button type="button" className="btn btn-custom-outline-success mb-2">{countdownTime.countdownDays} <div>Days</div></button>
                      <button type="button" className="btn btn-custom-outline-success mb-2">{countdownTime.countdownHours} <div>Hours</div></button>
                      <button type="button" className="btn btn-custom-outline-success mb-2">{countdownTime.countdownMinutes} <div>Minutes</div></button>
                      <button type="button" className="btn btn-custom-outline-success mb-2">{countdownTime.countdownSeconds} <div>Seconds</div></button>
                    </>
                    : <p>Deal has been Expired</p>}
                </div>
              </div>
            </div>



            <ListBox data={swarnim1_download} />
            <ListBox data={swarnim2_download} />
          </div>


          <div>
            <div className="">
              <div className="mt-4">
                <div >
                  <h5 className="fw-bold text-animated text-capitalize">
                    News Updates
                  </h5>
                </div>

                <div className="mt-3">
                  <div className="marquee-container">
                    <div className="marquee">
                      <div className="row">
                        {
                          newsData?.length > 0 ? (
                            newsData.map((news) => (
                              <a key={news.id}
                                onClick={(e) => {
                                  if (!news.pdf)
                                    e.preventDefault()
                                }}
                                href={`${Config.docUrl}${news.pdf}`} target='_blank'
                                className='d-block text-dark text-decoration-none'>
                                <div className="d-flex flex-column">
                                  <div className="title-marquee">
                                    <i className="bi bi-calendar-date me-3 text-blue"></i>
                                    <h4 className="heading-marquee">{moment(news.date).format("DD MMM YYYY")}
                                      <span className='text-animated d-inline rounded ms-1 badge lh-sm' style={{ fontSize: "12px" }}> <i className='bi bi-arrow-left fs-6 me-1'></i> Check Now  </span>
                                    </h4>
                                  </div>

                                  <p className="paragraph-marquee">
                                    {news.news}
                                  </p>
                                  <div className="line-marquee"></div>
                                </div>
                              </a>
                            ))
                          ) : (
                            <p>No news available</p>
                          )
                        }
                      </div>
                    </div>
                  </div>

                </div>

                <div className='my-4'>
                  <Link to={`${Config.appUrl}news-updates`} className='fs-5 text-green text-decoration-none fw-semibold'>View all news updates <i className='bi bi-arrow-right ms-2'></i></Link>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default MainHomeSection